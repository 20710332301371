.bp-video-widget {
  width: 100%;
}

.bp-image-widget {
  max-width: 100%;
}

.apos-area .to_animate{
  visibility:visible;
}

.flex-row-reverse {
  flex-direction:row-reverse;
}
@media screen and (min-width: 1200px){
  .side-item.backgrounded-media .item-media.reverse > * {
    margin-left: -50%
  }
}